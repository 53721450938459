import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

export default class ProfessionCarouselWidget {
  constructor() {
    new Swiper('.profession-swiper', {
      modules: [Navigation],

      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      spaceBetween: 10,
      watchSlidesProgress: true,

      breakpoints: {
        1800: {
          spaceBetween: 20,
          centeredSlides: true,
        },
        1200: {
          centeredSlides: false,
        },
        768: {
          centeredSlides: false,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
}
